<template>
    <div class="breadcrumbs pt-2">
        <v-breadcrumbs
            class="px-0"
            :items="steps"
            divider=""
        >
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                    :disabled="item.id != active"
                >
                    <v-icon :color="item.id == active ? 'primary' : ''" size="1.2rem">{{item.icon}}</v-icon>

                    <div class="step-text-wrapper ms-2">
                        <span :class="{'primary--text' : item.id == active, 'text-uppercase': true}">{{$t(item.label)}}</span>
                        <span v-if="showNbrProducts && item.id == active" class="nbr-products">{{nbrProductsInBasket}}</span>
                    </div>
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
    </div>
</template>

<script>
var Utils = require("@/assets/js/Utils");

export default {
    props:{
        active: Number,
        showNbrProducts: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            steps: Utils.getSteps()
        }
    },
    methods:{
    }
}
</script>

<style scoped>

.breadcrumbs ul {
    place-content: space-evenly;
}

.step-text-wrapper{
    position: relative;
    font-size: 0.84rem;
}

.nbr-products{
    font-size: 0.6rem;
    background: var(--color-primary);
    width: 16px;
    height: 16px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: -12px;
    right: -16px;
    left: auto;
}
</style>
