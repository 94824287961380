<template>
    <v-card class="d-flex flex-column align-center rounded-xl terminal-validation-dialog">
        <template v-if="paymentValid">
            <v-card-text class="d-flex pb-0 justify-center primary--text text-body-2">
                <v-img class="mt-4" width="100" height="100" :src="require(`@/assets/img/icon-check.png`)" contain />
            </v-card-text>
            <v-card-title class="mx-3 align-self-start font-weight-bold primary--text text-body-1 word-break">{{$t("scanTerminalValidationDialogSuccess")}}</v-card-title>
            <v-divider class="mx-3 full-width primary" inset />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="font-weight-bold rounded-lg py-2 px-6" color="primary" @click="handleShowReceipt()"> {{$t("scanTerminalValidationDialogSuccessBtnLabel")}} </v-btn>
            </v-card-actions>
        </template>
        <template v-else>
            <v-card-text class="d-flex pb-0 justify-center primary--text text-body-2">
                <v-img class="mt-4" width="100" height="100" :src="require(`@/assets/img/icon-cross.png`)" contain />
            </v-card-text>
            <v-card-title class="mx-3 align-self-start font-weight-bold primary--text text-body-1 word-break">{{$t("scanTerminalValidationDialogFailed")}} </v-card-title>
            <v-divider class="mx-3 full-width primary" inset />
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="font-weight-bold rounded-lg py-2 px-6" color="primary" @click="handleGoToPaymentMethods()" small> {{$t("scanTerminalValidationDialogFailedBtnLabel")}} </v-btn>
            </v-card-actions>
        </template>
    </v-card>
</template>

<script>
    export default {
        name: "ProductsCheckValidationDialog",
        props: {
            paymentValid: {
                type: Boolean
            }
        },
        data() {
            return {
                validOrder: false,
                validationComplete: false
            }
        },
        methods: {
            handleShowReceipt() {
                this.$router.push( { name: "Ticket" } )
            },
            handleGoToPaymentMethods() {
                this.$router.push( { name: "PaymentMethods" } )
            }
        }
    }
</script>

<style scoped>
    .word-break{
        word-break: break-word !important;
    }
</style>