<template>
  <div @click="simulateScan" :class="{'scan': true, 'white': loading}">
    <template v-if="loading">
        <div class="d-flex flex-column align-center " :style="{paddingTop: `${calculateHeaderHeight()}px`}">
            <Breadcrumbs :active="2"/>
            <p class="ma-0 mt-10 px-4 scan-terminal-loading-header-text primary--text font-weight-bold text-body-2 text-center">{{$t("scanTerminalWatingText1")}}</p>
            <v-img class="my-10" width="250" height="250" :src="require(`@/assets/img/icon-terminal-wait.png`)" contain />
            <p class="ma-0 px-4 scan-terminal-loading-header-text primary--text font-weight-bold text-body-2 text-center">{{$t("scanTerminalWatingText2")}}</p>
        </div>
    
        <v-dialog v-model="paymentDone" persistent>
            <TerminalPaymentValidationDialog :paymentValid="paymentValid" />
        </v-dialog>
    </template>
    <template v-else>
        <ScannerCamera />
    	<div class="target-camera">
            <div class="scanner-explanation" :style="{paddingTop: `${calculateHeaderHeight()}px`}">
                <p v-if="!error" class="ma-0 px-3 py-5 white--text text-body-1 text-center">{{$t("scanTerminalExplain")}}</p>
                <p v-else class="ma-0 px-3 py-5 white--text text-body-1 text-center">{{$t("scanTerminalErrorExplain")}}</p>
            </div>
    	</div>
    
    	<audio id="beep" ref="beep" controls hidden>
    	  <source src="@/assets/sounds/beep_100_0_1.wav" type="audio/wav">
    	  {{$t('errorSound')}}
    	</audio>
    </template>
  </div>
	
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ScannerCamera from "@/components/subcomponents/ScannerCamera.vue";
import TerminalPaymentValidationDialog from "@/components/TerminalPaymentValidationDialog.vue";
const Barcode = require("@/assets/js/Barcode");
const BasketService = require("@/services/BasketService")
import {mapActions} from 'vuex'

export default {
  name: 'ScanTerminal',
  components: { ScannerCamera, Breadcrumbs, TerminalPaymentValidationDialog },
  data() {
	return {
	  cameras: [],
	  cameraToUse: null,
      paymentStarted: false,
      error: false,
      loading: false,
      paymentValid: false,
      paymentDone: false,
	}
  },
  mounted () {
      Barcode.start(this.onDecode);
  },
  methods:{
    ...mapActions('header', [ 'changeCurrentPage' ]),
    simulateScan(){
        let codeToMock = {
            codeResult: {
                code: "payment_lane:LANE50001"
            }
        }
        this.onDecode(codeToMock)
    },
	// async getCameras(){
	//   this.cameras = await Barcode.getCameras()
	// },

	onDecode(decodedString) {
        
        let code = decodedString.codeResult.code;
        let yomaniKey = "payment_weshop_yomani";
        let valinaKey = "payment_weshop";
        let septagoneKey = "payment_septagone";
        let laneKey = "payment_lane";
        let moveKey = "payment_DKS";

        if(!this.paymentStarted) {
            this.loading = true
            this.$refs.beep.play();
            let splittedCode = code.split(":") // 0: payment key 1: terminal ID
            this.paymentStarted = true
            switch(splittedCode[0].trim()){
                case yomaniKey:
                    this.handleYomaniPayment(splittedCode[1]);
                    break;
                case valinaKey:
                    this.handleValinaPayment(splittedCode[1]);
                    break;
                case septagoneKey:
                    this.handleSeptagonePayment(splittedCode[1]);
                    break;
                case moveKey:
                    this.handleMovePayment(splittedCode[1]);
                    break;
                case laneKey:
                    this.handleLanePayment(splittedCode[1]);
                    break;
                default:
                    this.error = true
                    setTimeout(() => { this.error = false }, 5000);
                    break;
            }

            
        }
    },
    handleValinaPayment(terminalId){
        let paymentTopicTerminal = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.VALINA_PAYMENT.format({ terminalId });
        let paymentTopicTicket = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_TICKET.format({ complexId: this.customerComplexId });

        let message = {
            terminalId : terminalId,
            userId : this.customerComplexId,
            basket : this.formattedBasket,
            basketPrice : this.basketTotalAmount, 
            merchantRef : "",
            orderId : `1000${this.basketId}`
        }
        
        if(this.isNotNull(this.customerEmail)){
            message.userEmail = this.customerEmail;
        }
        this.$mqtt.subscribeMqtt(paymentTopicTicket, () => {
                this.$mqtt.publishMqtt(paymentTopicTerminal, JSON.stringify(message), () => {});
                this.$mqtt.listenMqttMessage(paymentTopicTicket, message => {
                    let recievedMessage = JSON.parse(message.toString())
                    this.hideLoader()
                    if (recievedMessage.terminalPayment != null) {
                        this.paymentValid = true
                    }else if(recievedMessage.error != null) {
                        this.paymentValid = false
                    }
                    this.paymentDone = true
                })
        })
    },
    handleYomaniPayment(terminalId){
        
        let paymentTopicTerminal = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.YOMANI_PAYMENT.format({ terminalId });
        let paymentTopicTicket = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_TICKET.format({ complexId: this.customerComplexId });

        let message = {
            terminalId : terminalId,
            userId : this.customerComplexId,
            basket : this.formattedBasket,
            basketPrice : this.basketTotalAmount, 
            merchantRef : "",
            orderId : `1000${this.basketId}`
        }
        
        if(this.isNotNull(this.customerEmail)){
            message.userEmail = this.customerEmail;
        }
        this.$mqtt.subscribeMqtt(paymentTopicTicket, () => {
                this.$mqtt.publishMqtt(paymentTopicTerminal, JSON.stringify(message), () => {});
                this.$mqtt.listenMqttMessage(paymentTopicTicket, message => {
                    let recievedMessage = JSON.parse(message.toString());
                    this.hideLoader()
                    if (recievedMessage.terminalPayment != null) {
                        this.updateYomaniTicket(recievedMessage.clientTicket)
                        this.paymentValid = true
                    }else if(recievedMessage.error != null) {
                        this.paymentValid = false
                    }
                    this.paymentDone = true
                })
        })
    },
    handleSeptagonePayment(terminalId){
        
        let paymentTopicTerminal = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.SEPTAGONE_PAYMENT.format({ terminalId });
        let paymentTopicTicket = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_TICKET.format({ complexId: this.customerComplexId });

        let message = {
            terminalId : terminalId,
            userId : this.customerComplexId,
            basket : this.formattedBasket,
            basketPrice : this.basketTotalAmount, 
            merchantRef : "",
            orderId : `1000${this.basketId}`
        }
        console.log(message)
        if(this.isNotNull(this.customerEmail)){
            message.userEmail = this.customerEmail;
        }
        this.$mqtt.subscribeMqtt(paymentTopicTicket, () => {
                this.$mqtt.publishMqtt(paymentTopicTerminal, JSON.stringify(message), () => {});
                this.$mqtt.listenMqttMessage(paymentTopicTicket, message => {
                    let recievedMessage = JSON.parse(message.toString());
                    this.hideLoader()
                    if (recievedMessage.terminalPayment != null) {
                        this.paymentValid = true
                    }else if(recievedMessage.error != null) {
                        this.paymentValid = false
                    }
                    this.paymentDone = true
                })
        })

    },
    async handleLanePayment(terminalId){

        if(terminalId){
            console.log("Lane" + terminalId)
            let paymentInitTopicTerminal = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_LANE_INIT.format({ terminalId });
            let paymentDoneTopic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_LANE_DONE.format({ terminalId });

            let req = {
                amount : parseInt(this.basketTotalAmount * 100),
                orderId : `1000${this.basketId}`
            }
            this.$mqtt.subscribeMqtt(paymentDoneTopic, () => {
                this.$mqtt.publishMqtt(paymentInitTopicTerminal, JSON.stringify(req), () => {});
                this.$mqtt.listenMqttMessage(paymentDoneTopic, message => {
                    let recievedMessage = JSON.parse(message.toString());
                    this.hideLoader()
                    if(recievedMessage.orderId && recievedMessage.orderId == req.orderId){
                        let transactionStatus = recievedMessage.transactionStatus;
                        switch(transactionStatus){
                            case "DONE":
                                this.hideLoader()
                                this.paymentValid = true
                                this.paymentDone = true
                                break;
                            case "WAITING":
                                break;
                            case "IN_USE":       
                                break;
                            case "REFUSED":                                
                                this.paymentValid = false
                                this.paymentDone = true
                                this.hideLoader()
                                break;
                        }
                    }
                })
            })
        }
    },
    async handleLanePayment(terminalId){
        console.log("Move" + terminalId)
        if(terminalId){
            let {data} = await BasketService.initLanePayment(this.customerToken, terminalId, this.basketId)
            if(data){
                let transactionId = data.transactionId
                console.log(transactionId)
                if(transactionId){
                    let checkInterval = setInterval(async () => {
                        console.log("cheking state")
                        let { data } = await BasketService.checkMovePayment(this.customerToken, transactionId)
                        let transactionStatus = data.transactionStatus;
                        switch(transactionStatus){
                            case "DONE":
                                this.hideLoader()
                                this.paymentValid = true
                                this.paymentDone = true
                                break;
                            case "WAITING":
                                break;
                            case "REFUSED":
                                clearInterval(checkInterval)
                                //TODO Handling Errors 
                                
                                this.paymentValid = false
                                this.paymentDone = true
                                this.hideLoader()
                                break;
                        }
                    }, 5000);
                    BasketService.checkMovePayment(this.customerToken, transactionId)
                }
            }
        }
    },
    calculateHeaderHeight(){
      return document.getElementById("app-header").offsetHeight || 0
    }
  },
  beforeRouteLeave(to, from, next) {
	/* eslint-disable */
	Barcode.reset()
	next();
  },
  watch:{
	cameras(){
	  Barcode.reset()
	  this.cameraToUse = this.cameras[0]
	},
	cameraToUse(camera){
	  Barcode.reset()
	  Barcode.start(camera.device, this.onDecode);
	},
    loading(){
        if(this.loading){
            Barcode.reset()
        }else{
	        this.getCameras()
        }
    }
  }
  
};
</script>
<style scoped>
.scan{
    height: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: black;
    position: relative;
}
.target-camera{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
